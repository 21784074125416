import React, { Component } from 'react';
import { getUserRecentLogins } from '../services/recentLogins';
import NavItemExpandable from './NavItemExpandable';

const NUM_MAX_LINKS = 5;

export default class RecentlyVisited extends Component {
  state = { loaded: false, loading: false };

  loadRecentLogins = () => {
    if (this.props.accessToken && !this.state.loading && !this.state.loaded) {
      this.setState({ loading: true });
      getUserRecentLogins(this.props.accessToken)
        .then(data => {
          if (!this._isMounted) {
            return false;
          }
          this.setState({ data: data, loaded: true, loading: false });
        })
        .catch(() => {
          if (!this._isMounted) {
            return false;
          }
          this.setState({ loading: false });
        });
    }
  };

  componentDidMount = () => {
    this.loadRecentLogins();
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate = () => {
    this.loadRecentLogins();
  };

  render() {
    const { loaded, data } = this.state;

    if (!this.props.accessToken) {
      return null;
    }

    const navItem = {
      id: 'recentlyVistited',
      text: 'Recently Visited',
      svg: 'rank-army-star-2-l',
      subItems: (data || []).slice(0, 4).map(loginItem => ({
        id: loginItem.clientId,
        text: loginItem.title,
        url: loginItem.link,
      })),
    };
    return <NavItemExpandable item={navItem} isActive={true} key={navItem.id} />;
  }
}
